import { type Location, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isPredeliverRoute, isRolloverRoute } from '@/App/routes';
import { Spinner } from '@/share/Spinner/Spinner';
import { executeAction } from '@/store/state/rfs/rfs.actions';
import { selectRfsStatus } from '@/store/state/rfs/rfs.selectors';
import { selectCurrentUser, selectNavigateAsUser } from '@/store/state/user/user.selectors';
import { selectBlockExecution } from '@/store/state/ui/ui.selectors';
import type { TradeActionType } from '@/models/trade';
import { useAppDispatch } from '@/store/store';

interface ExecuteButtonProps {
  customClass?: string;
}

function getLabel({ pathname }: Location): TradeActionType | 'execute' {
  if (isRolloverRoute(pathname)) {
    return 'rollover';
  }
  if (isPredeliverRoute(pathname)) {
    return 'predeliver';
  }
  return 'execute';
}

export function ExecuteButton({ customClass = 'btn-xl col-12' }: ExecuteButtonProps) {
  const location = useLocation();
  const rfsStatus = useSelector(selectRfsStatus);
  const usrNavigateAsUser = useSelector(selectNavigateAsUser);
  const currentUser = useSelector(selectCurrentUser);
  const blockExecution = useSelector(selectBlockExecution);

  const label = getLabel(location);
  const isExecutable = rfsStatus === 'quoting' && usrNavigateAsUser === undefined && currentUser!.canExecute && !blockExecution;
  const isExecuting = rfsStatus === 'executing';

  const dispatch = useAppDispatch();

  const onExecute = () => dispatch(executeAction());

  return (
    <button type="button" className={`btn btn-success execute ${customClass} ${isExecutable ? '' : 'disabled'}`} disabled={!isExecutable} onClick={onExecute}>
      <FormattedMessage id={`trader.executeButton.${label}`} /> {isExecuting && <Spinner />}
    </button>
  );
}
