import type { Currency } from '@/models/currency';
import type { Rfs } from '@/models/rfs';
import type { MyHedgeTrade } from '@/models/trade';
import { type Way, wayToMasterAmount } from '@/models/way';
import type { AppState } from '@/store/app.state';
import { getCurrencyPairPrecision } from '@/utils/currencies';
import { convertToPointsWithPrecision, formatAmount, formatAmountWithPrecision } from '@/utils/format';
import type { Locale } from '@/utils/locale';

/// ///////////////////////////////
export const getSpotRate = (rfs: Rfs) => ('spotRate' in rfs && rfs.spotRate !== undefined ? rfs.spotRate : undefined);
export const getSpotPrecision = (rfs: Rfs) =>
  rfs.masterAmount === 'buyAmount' ? getCurrencyPairPrecision(rfs.buyCurrency, rfs.sellCurrency) : getCurrencyPairPrecision(rfs.sellCurrency, rfs.buyCurrency);

export const getFormattedSpotRate = (locale: Locale) => (rfs: Rfs) =>
  'spotRate' in rfs && rfs.spotRate !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.spotRate, getSpotPrecision(rfs))
    : undefined;

export const getFormattedHistoricalDealOffRate = (locale: Locale) => (rfs: Rfs) =>
  'historicalDealOffRate' in rfs && rfs.historicalDealOffRate !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.historicalDealOffRate, getSpotPrecision(rfs))
    : undefined;

/// ///////////////////////////////
export const getForwardPoints = (rfs: Rfs) => ('forwardPoints' in rfs && rfs.forwardPoints !== undefined ? rfs.forwardPoints : undefined);
export const getFormattedForwardPoints = (locale: Locale) => (rfs: Rfs) =>
  'forwardPoints' in rfs && rfs.forwardPoints !== undefined ? formatAmount(locale)(rfs.forwardPoints) : undefined;
export const getForwardRate = (rfs: Rfs) => ('forwardRate' in rfs && rfs.forwardRate !== undefined ? rfs.forwardRate : undefined);
export const getForwardPrecision = (rfs: Rfs) => getSpotPrecision(rfs) + 1;
export const getFormattedForwardRate = (locale: Locale) => (rfs: Rfs) =>
  'forwardRate' in rfs && rfs.forwardRate !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.forwardRate, getForwardPrecision(rfs))
    : undefined;

/// ///////////////////////////////
export const getPredeliverPoints = (rfs: Rfs) => ('predeliverPoints' in rfs && rfs.predeliverPoints !== undefined ? rfs.predeliverPoints : undefined);
export const getFormattedPredeliverPoints = (locale: Locale) => (rfs: Rfs) => {
  const toPoints = (n: number | null) => convertToPointsWithPrecision(n, getSpotPrecision(rfs));
  return 'predeliverPoints' in rfs && rfs.predeliverPoints !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(toPoints(rfs.predeliverPoints), 2)
    : undefined;
};

export const getPredeliverRate = (rfs: Rfs) => ('predeliverRate' in rfs && rfs.predeliverRate !== undefined ? rfs.predeliverRate : undefined);
export const getFormattedPredeliverRate = (locale: Locale) => (rfs: Rfs) =>
  'predeliverRate' in rfs && rfs.predeliverRate !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.predeliverRate, getForwardPrecision(rfs))
    : undefined;

/// ///////////////////////////////
export const getRolloverPoints = (rfs: Rfs) => ('rolloverPoints' in rfs && rfs.rolloverPoints !== undefined ? rfs.rolloverPoints : undefined);
export const getFormattedRolloverPoints = (locale: Locale) => (rfs: Rfs) => {
  return 'rolloverPoints' in rfs && rfs.rolloverPoints !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.rolloverPoints, getForwardPrecision(rfs))
    : undefined;
};

export const getFormattedRolloverRate = (locale: Locale) => (rfs: Rfs) =>
  'rolloverRate' in rfs && rfs.rolloverRate !== undefined && rfs.buyCurrency !== undefined && rfs.sellCurrency !== undefined
    ? formatAmountWithPrecision(locale)(rfs.rolloverRate, getForwardPrecision(rfs))
    : undefined;

function getCurrencyField(way: Way): 'buyCurrency' | 'sellCurrency' {
  switch (way) {
    case 'buy':
      return 'buyCurrency';
    case 'sell':
      return 'sellCurrency';
    default: {
      const exhaustiveCheck: never = way;
      return exhaustiveCheck;
    }
  }
}

export const getCurrencyByWay = (rfs: Rfs, way: Way): Currency => rfs[getCurrencyField(way)];

export function isMasterWay(rfs: Rfs, way: Way) {
  return rfs.masterAmount === wayToMasterAmount(way);
}

export function getAmountByWay(rfs: Rfs, way: Way) {
  return rfs[wayToMasterAmount(way)];
}
export function hasWrittenOnSide(rfs: Rfs, way: Way) {
  return isMasterWay(rfs, way) && rfs[rfs.masterAmount] !== 0;
}

// spec: https://itbox-jira.fr.world.socgen/jira/browse/SGEFX-3584
export function getRolloverImpact(rollover: Rfs, trade: MyHedgeTrade) {
  if (rollover.status !== 'quoting' || rollover.product !== 'FxRollover') {
    return undefined;
  }

  const editedAmount = rollover[wayToMasterAmount(trade.side)];
  const initialRate = trade.strike;

  return trade.side === 'sell'
    ? editedAmount / trade.strike - editedAmount / rollover.nearRate //
    : editedAmount / rollover.nearRate - editedAmount / initialRate; //
}

export const selectRfs = ({ rfs }: AppState) => rfs;

export const selectRfsStatus = ({ rfs }: AppState) => rfs.status;
